<script lang="ts" setup>
import Parallaxy from '@lucien144/vue3-parallaxy'
import Link2 from '@/components/Button/Link2.vue'
import Title1 from '@/components/Title/Title1.vue'
import { type HomeSectionList } from '@/utils/types'
import Section2Slide from './Section2Slide.vue'
import { usePageStore } from '@/stores'
const pageStore = usePageStore()
const blockData = computed(() => pageStore.homeSection2)
const eventData = computed(() => pageStore.homeEvents)

const props = defineProps({
  bgColor: {
    type: String,
    default: ''
  }
})

const lists: Ref<HomeSectionList[]> = ref([
  {
    pic: blockData.value.image_sm,
    speed: 90,
    direction: 'normal'
  } as HomeSectionList,
  {
    pic: blockData.value.image_lg,
    speed: 80,
    direction: 'normal'
  } as HomeSectionList
])

const slideLists: Ref<HomeSectionList[]> = ref([])
eventData.value.forEach((o: any) => {
  const item: HomeSectionList = {
    pic: o.image,
    title: o.subject,
    link: `/events/${o.id}`
  }
  slideLists.value.push(item)
})
</script>

<template>
  <section class="section-box">
    <div class="wrap relative">
      <div class="section-number">
        <Parallaxy :breakpoints="{ 991: { axis: 'y', speed: 200 } }" :speed="0"
          :animation="(delta: number) => `transform: translate3d(0, ${delta}px, 0);`">
          <SectionNumber no="2" :class="{ brown: props.bgColor === '#f2f2f2' }" />
        </Parallaxy>
      </div>
      <div v-for="(item, index) in lists" :key="index" :class="`lists lists-${index + 1}`">
        <Parallaxy :breakpoints="{ 991: { axis: 'y', speed: item.speed } }" :speed="0" :direction="item.direction"
          :animation="(delta: number) => `transform: translate3d(0, ${delta}px, 0);`">
          <div class="lists__pic">
            <Nimg :src="item.pic?.link" :alt="item.pic?.alt" :title="item.pic?.title" />
          </div>
        </Parallaxy>
      </div>
      <div class="section-title">
        <Title1 class="section-title__inner">
          <template v-slot:en>Events</template>
          <template v-slot:tw>精選活動</template>
          <template v-slot:link>
            <Link2 :to="blockData.url || '#'" linkTitle="立即探索" class="circle"></Link2>
          </template>
        </Title1>
      </div>
    </div>
    <div class="section-slide">
      <Section2Slide class="section-slide__first" :lists="slideLists" />
      <Section2Slide class="section-slide__second" :lists="slideLists" :reverse="true" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.section-box {
  @include min-media(991) {
    margin-bottom: toRem(100);
  }

  .wrap {
    @include grid;

    @include max-media(990) {
      max-width: toRem(520);
      @include grid(4, 24);
    }
  }
}

.section-number {
  position: relative;
  grid-column: 3 / span 2;
  width: toRem(111);
  height: toRem(113);
  top: toRem(-100);

  @include min-media(991) {
    transform: translateY(180px);
  }

  @include max-media(990) {
    width: 100%;
    height: auto;
    grid-column: 1 / span 1;
  }
}

.section-title {
  position: absolute;
  left: 0;
  top: toRem(150);
  width: 100%;
  @include grid;

  @include min-media(991) {
    padding-left: toRem(24);
    padding-right: toRem(24);
  }

  @include max-media(990) {
    position: relative;
    @include grid(4, 24);
    order: 4;
    grid-column: 1 / span 4;
    top: 0;
  }

  &__inner {
    grid-column: 5 / span 3;

    @include max-media(990) {
      grid-column: 2 / span 3;
    }
  }
}

.lists {
  img {
    display: block;
    width: 100%;
  }

  &-1 {
    grid-column: 1 / span 2;

    @include max-media(990) {
      grid-column: 2 / span 1;
      grid-row: none;
      margin-right: toRem(-24);
    }
  }

  &-2 {
    grid-column: 9 / span 4;

    @include max-media(990) {
      grid-column: 3 / span 2;
    }
  }

  &__pic {
    width: 100%;

    img {
      display: block;
      width: 100%;
    }
  }
}

.section-slide {
  @include max-media(990) {
    padding-top: toRem(70);
  }

  &__first {
    margin-bottom: toRem(10);
  }

  &__second {
    @include max-media(990) {
      display: none;
    }
  }
}
</style>
